import React, { ReactChild, ReactElement, useState } from "react";

const PasswordBox = ({
  password,
  setIsLocked,
}: {
  password: string;
  setIsLocked: Function;
}) => {
  const [input, setInput] = useState("");
  const handlePassword = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (input === password) setIsLocked(false);
  };
  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
  };
  return (
    <form onSubmit={handlePassword}>
      <input type="password" onChange={handleInput} />
    </form>
  );
};

const PasswordWall = ({
  password,
  setIsLocked,
  children,
}: {
  password: string;
  setIsLocked: Function;
  children: ReactElement;
}) => {
  return (
    <div
      style={{
        position: "fixed",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        background: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        zIndex: 919239123,
      }}
    >
      {children}
      <PasswordBox password={password} setIsLocked={setIsLocked} />
    </div>
  );
};

const PasswordProtected = ({
  password,
  children,
  unlocked,
}: {
  password: string;
  children: ReactChild;
  unlocked: boolean;
}) => {
  const [isLocked, setIsLocked] = useState(unlocked ? false : true);
  return (
    <>
      {isLocked ? (
        <PasswordWall password={password} setIsLocked={setIsLocked}>
          <h1>Under Construction</h1>
        </PasswordWall>
      ) : (
        children
      )}
    </>
  );
};

export default PasswordProtected;
