/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
require("./node_modules/bootstrap/dist/css/bootstrap.css");
require("./node_modules/wmk-lib/dist/index.css");

const React = require('react')
const AppWrapper = require("./src/components/context/AppWrapper").default

exports.wrapPageElement = ({element, props}) => {
    return <AppWrapper>{element}</AppWrapper>
}