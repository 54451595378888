import React, { ReactChildren } from "react";
// import { HubUserProvider } from "./HubspotContext";
import PasswordProtected from "./PasswordProtected";
// import { UserProvider } from "./UserContext";

const AppWrapper = ({ children }: { children: ReactChildren }) => {
  return (
    <PasswordProtected password="welcome1" unlocked>
      {/* <HubUserProvider 
      // asUser={5842969} Peter
      //asUser={5853852} Rando w/ phone
      > */}
      {/* <UserProvider> */}
        {children}
        {/* </UserProvider> */}
      {/* </HubUserProvider> */}
    </PasswordProtected>
  );
};

export default AppWrapper;
